<template>
  <ProductTitledBlock
    v-if="showPropertiesBlock"
    :title="model.title || $t('shared.catalog.product_details.technical_specs_block_title')"
    icon="adjustments"
  >
    <VcCollapsibleContent max-height="12.5rem">
      <div class="space-y-4">
        <!-- Vendor -->
        <VcProperty v-if="showVendor" :label="$t('shared.catalog.product_details.vendor_label')" class="text-base">
          <Vendor :vendor="product.vendor!" with-rating />
        </VcProperty>
        <!-- OPUS -->
        <VcProperty v-for="property in properties" :key="property.name" :label="property.label!" class="text-base">
          <VcTooltip
            v-if="isHTML(property)"
            class="op-html-property"
            :text="property.value"
            strategy="fixed"
            width="max-content"
            :hover="false"
          >
            <template #trigger>
              <div v-html-safe="property.value" class="op-html-property_trigger" />
            </template>

            <template #content>
              <div v-html-safe="property.value" class="op-html-property_content" />
            </template>
          </VcTooltip>
          <a
            v-else-if="isFileLink(property)"
            :href="property.value as string"
            target="_blank"
            class="cursor-pointer text-[--link-color] hover:text-[--link-hover-color]"
            @click="downloadFile(property.name, property.value as string)"
            >{{ $t("shared.catalog.product_details.view_file") }}
          </a>
          <span v-else :title="String(property.value)">{{ property.value }}</span>
        </VcProperty>
        <!-- Rating -->
        <VcProperty
          v-if="productReviewsEnabled && product.rating"
          :label="$t('shared.catalog.product_card.product_rating')"
          class="text-base"
        >
          <ProductRating :rating="product.rating" />
        </VcProperty>
        <VcProperty v-if="sku" :label="$t('shared.catalog.product_card.sku')" class="text-base">
          {{ sku }}
        </VcProperty>
        <!-- !OPUS -->
      </div>
    </VcCollapsibleContent>
    <!-- Properties -->
  </ProductTitledBlock>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { PropertyType, PropertyValueTypes } from "@/core/api/graphql/types";
import { useThemeContext } from "@/core/composables";
import { useModuleSettings } from "@/core/composables/useModuleSettings";
import { getPropertiesGroupedByName } from "@/core/utilities";
import {
  MODULE_ID as CUSTOMER_REVIEWS_MODULE_ID,
  ENABLED_KEY as CUSTOMER_REVIEWS_ENABLED_KEY,
} from "@/modules/customer-reviews/constants";
import { ProductTitledBlock, Vendor } from "@/shared/catalog";
import { generateLinkAndDownloadFile } from "@/shared/opus";
import type { Product, Property } from "@/core/api/graphql/types";
import ProductRating from "@/modules/customer-reviews/components/product-rating.vue";

interface IProps {
  product: Product;
  model: {
    title?: string;
    hidden?: boolean;
  };
  // OPUS
  sku?: string;
  // !OPUS
}

const props = defineProps<IProps>();

const { themeContext } = useThemeContext();
const { isEnabled } = useModuleSettings(CUSTOMER_REVIEWS_MODULE_ID);
const productReviewsEnabled = isEnabled(CUSTOMER_REVIEWS_ENABLED_KEY);

const properties = computed(() =>
  Object.values(getPropertiesGroupedByName(props.product.properties ?? [], PropertyType.Product)).filter(
    (property) => property.value,
  ),
);

const showVendor = computed(
  () => themeContext.value?.settings?.vendor_enabled && !props.product.hasVariations && props.product.vendor,
);
const showPropertiesBlock = computed(() => !props.model.hidden && (properties.value.length || showVendor.value));

function isHTML(property: Property): boolean {
  return (property.propertyValueType as PropertyValueTypes) === PropertyValueTypes.Html;
}

// OPUS
async function downloadFile(name: string, url: string) {
  if (name && url) {
    await generateLinkAndDownloadFile(name, url);
  }
}

function isFileLink(property: Property): boolean {
  if (property.value && typeof property.value === "string") {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    const filePattern = /\.(jpg|jpeg|png|pdf|doc|docx|xls|xlsx|ppt|pptx)$/i;
    return urlPattern.test(property.value) && filePattern.test(property.value);
  } else {
    return false;
  }
}
// !OPUS
</script>

<style lang="scss">
.op-html-property {
  img {
    @apply inline-block align-bottom;
  }

  &_trigger {
    @apply truncate;
  }

  &_content {
    @apply shadow-sm w-full rounded-sm px-2.5 py-1.5 text-base whitespace-normal max-w-72 md:max-w-96;
  }
}
</style>
