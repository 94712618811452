import type { OpusContactType, OrganizationSector, SupplierType, FacetTermType } from "@/core/api/graphql/types";
import type { OpusApprovalOrderStatuses } from "@/shared/opus";

export enum RegistrationKind {
  personal = "personal",
  organization = "organization",
}

export type RegisterOrganizationType = {
  userName: string;
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  organizationName?: string;
};

export type SignMeUpType = {
  userName: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type ForgotPasswordType = {
  email: string;
  resetPasswordUrlPath: string;
};

export type ResetPasswordType = {
  userId: string;
  token: string;
  password: string;
};

export type ChangePasswordType = {
  userId: string;
  oldPassword: string;
  newPassword: string;
};

export type CheckoutDefaultsType = {
  deliveryMethod?: string;
  shippingMethodId?: string;
  paymentMethodCode?: string;
};

export type UserPersonalDataType = {
  firstName: string;
  lastName: string;
  defaultLanguage?: string;
  currencyCode?: string;
  phones?: string[];
  // OPUS
  jobTitle?: string;
  // !OPUS
};

export type OrdersFilterDataType = {
  statuses: string[];
  startDate?: string;
  endDate?: string;
  customerNames?: string[];
  // OPUS
  suppliers: SupplierType[];
  approvalStatuses: OpusApprovalOrderStatuses[];
  buyers: OpusContactType[];
  // !OPUS
};

export type OrdersFilterChipsItemType = {
  fieldName: keyof OrdersFilterDataType;
  value?: string;
  label: string;
};

export type OrderScopeType = "private" | "organization";

export type OrderFacetType = {
  name: string;
  items: FacetTermType[];
};

// OPUS
export enum OrganizationStatus {
  new = "New",
  approved = "Approved",
  unverified = "Unverified",
}

export type OpSignMeUpType = {
  userName: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  organizationName?: string;
  phoneNumber?: string;
  jobTitle?: string;
  zipCode?: string;
  discoveryWay: string;
  createdWay?: string;
  subSource?: string;
  organizationType: OrganizationSector;
};

export type OpRegisterOrganizationType = {
  userName: string;
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  organizationName?: string;
  phoneNumber?: string;
  jobTitle?: string;
  zipCode?: string;
  discoveryWay: string;
  createdWay?: string;
  subSource?: string;
};

export type OpShortOrderType = {
  CustomerOrderNumber: string;
  SupplierOrderNumber?: string;
  SupplierName?: string;
  CreatedDate: string;
  CustomerName?: string;
  PaymentType?: string;
  SupplierOrderStatus?: string;
  ShippingTotal?: string;
  TaxTotal?: string;
  SupplierOrderEstimatedTotal?: string;
  UpdatedShippingTotal?: string;
  UpdatedTaxTotal?: string;
  SupplierOrderActualTotal?: string;
  ContractNumbers?: string | string[];
};
// !OPUS
