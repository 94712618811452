<template>
  <div class="relative" :class="{ group: !isAuthenticated || user?.passwordExpired }">
    <nav
      ref="bottomHeader"
      :class="{ 'group-hover:pointer-events-none': !isAuthenticated || user?.passwordExpired }"
      class="relative z-[2] flex min-h-[5.5rem] items-center gap-x-5 bg-[--header-bottom-bg-color] px-5 py-3 xl:px-12"
    >
      <router-link
        :tabindex="tabAccessabilityIndex"
        :class="{ 'pointer-events-auto': !isAuthenticated }"
        :disabled="user?.passwordExpired"
        :to="$context.settings.default_return_url ?? '/'"
      >
        <VcImage :src="logoUrl" :alt="$context.storeName" class="h-8 cursor-pointer xl:h-[2.8rem]" lazy />
      </router-link>

      <!-- OPUS -->
      <!-- <template v-if="organization">
        <div class="w-0.5 h-6 bg-primary hidden xl:block"></div>
        <div class="hidden max-w-[9rem] text-base font-medium italic leading-[18px] text-neutral-800 xl:line-clamp-2">
          {{ organization?.name }}
        </div>
      </template> -->
      <div
        v-if="!isAuthenticated"
        :tabindex="tabAccessabilityIndex"
        class="z-0 text-center text-14 font-bold text-accent opacity-0 transition-opacity group-hover:opacity-100"
        :class="{ 'unauthorized-warning': !isAuthenticated }"
      >
        {{ $t("shared.layout.header.bottom_header.warning_message") }}
      </div>
      <!-- !OPUS -->
      <div
        :tabindex="tabAccessabilityIndex"
        class="flex w-full flex-row items-center gap-x-5"
        :class="{ '!pointer-events-none opacity-10': !isAuthenticated || user?.passwordExpired }"
      >
        <SearchBar :tab-accessability-index="tabAccessabilityIndex" />
        <!-- Catalog button -->
        <a
          ref="showCatalogMenuButton"
          :tabindex="tabAccessabilityIndex"
          :href="catalogLink"
          type="button"
          class="flex select-none items-center rounded border-2 border-primary px-[0.8rem] py-[0.55rem] text-sm text-[--header-bottom-link-color] hover:text-[--header-bottom-link-hover-color]"
          @click="toggleCatalogDropdown"
        >
          <span class="font-bold tracking-wide">
            {{ $t("shared.layout.header.bottom_header.catalog_menu_button") }}
          </span>
          <VcIcon v-if="catalogMenuItems.length" :name="catalogButtonIcon" size="xs" class="ml-3 fill-primary" />
        </a>
        <ul class="-mx-2 flex items-center">
          <li v-for="item in desktopMainMenuItems" :key="item.id">
            <component :is="(item.id && customLinkComponents[item.id]) || LinkDefault" :item="item" />
          </li>
        </ul>
      </div>
    </nav>

    <!-- Catalog dropdown -->
    <transition
      v-if="catalogMenuItems.length"
      enter-from-class="-translate-y-full"
      leave-to-class="-translate-y-full"
      enter-active-class="will-change-transform"
      leave-active-class="will-change-transform"
    >
      <div
        v-if="catalogMenuVisible"
        ref="catalogMenuElement"
        class="absolute w-full overflow-y-auto bg-additional-50 shadow-md transition-transform duration-200"
        :style="catalogMenuStyle"
      >
        <div
          class="mx-[60px] mt-10 flex items-center rounded bg-primary px-2.5 py-2 text-13 font-bold text-additional-50"
        >
          <VcImage src="/static/images/opus/icons/info-2.png" class="mr-3 h-5" lazy />
          <span>{{ $t("common.messages.catalog_alert_info") }}</span>
        </div>
        <CatalogMenu
          :items="catalogMenuItems"
          @focusout="focusoutDropdown"
          @close="closeCatalogDropdown"
          @select="closeCatalogDropdown"
        />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, syncRefs, useElementBounding, useScrollLock } from "@vueuse/core";
import { computed, nextTick, ref, shallowRef, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useNavigations, useWhiteLabeling } from "@/core/composables";
import { useUser } from "@/shared/account/composables/useUser";
import { SearchBar } from "@/shared/layout";
import { useCustomHeaderLinkComponents } from "@/shared/layout/composables/useCustomHeaderLinkComponents";
import CatalogMenu from "./catalog-menu.vue";
import type { StyleValue } from "vue";
import LinkDefault from "@/shared/layout/components/header/_internal/link-components/link-default.vue";

const router = useRouter();
const { organization, isAuthenticated, user } = useUser();
const { logoUrl } = useWhiteLabeling();
const { catalogMenuItems, desktopMainMenuItems } = useNavigations();
const { customLinkComponents } = useCustomHeaderLinkComponents();

const bottomHeader = ref<HTMLElement | null>(null);
const tabAccessabilityIndex = computed(() => (isAuthenticated.value ? undefined : -1));
const catalogMenuElement = shallowRef<HTMLElement | null>(null);
const showCatalogMenuButton = shallowRef<HTMLElement | null>(null);
const catalogMenuVisible = ref(false);

const { bottom } = useElementBounding(bottomHeader);
const route = useRoute();

const catalogButtonIcon = computed<string>(() => (catalogMenuVisible.value ? "chevron-up" : "chevron-down"));
const catalogMenuStyle = computed<StyleValue | undefined>(() =>
  bottom.value ? { maxHeight: `calc(100vh - ${bottom.value}px)` } : undefined,
);

const catalogLink = router.resolve({ name: "Catalog" }).fullPath;

onClickOutside(
  catalogMenuElement,
  () => {
    catalogMenuVisible.value = false;
  },
  { ignore: [showCatalogMenuButton] },
);

syncRefs(catalogMenuVisible, useScrollLock(document.body));

// TODO: Redirect to localized catalog path if catalogMenuItems has not items
async function toggleCatalogDropdown(event: Event) {
  if (!catalogMenuItems.value.length) {
    return;
  }
  event.preventDefault();
  catalogMenuVisible.value = !catalogMenuVisible.value;
  await nextTick();
  if (catalogMenuVisible.value) {
    catalogMenuElement.value?.querySelector("a")?.focus();
  }
}

async function closeCatalogDropdown() {
  catalogMenuVisible.value = false;
  await nextTick();
  showCatalogMenuButton.value?.focus();
}

function focusoutDropdown(payload: FocusEvent) {
  if (payload.relatedTarget !== showCatalogMenuButton.value) {
    void closeCatalogDropdown();
  }
}

watch(route, () => {
  catalogMenuVisible.value = false;
});
</script>

<style>
.unauthorized-warning {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 186px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  background: #ffffff;
  box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
</style>
