import { graphqlClient } from "../../../client";
import getOrganizationOrdersQueryDocument from "./getOrganizationOrdersForApprovalQuery.graphql";
import type {
  OrderApprovalRequestConnection,
  Query,
  QueryOrderApprovalRequestsArgs,
  QueryOrganizationOrderApprovalRequestsArgs,
} from "@/core/api/graphql/types";

export async function getOrganizationOrdersForApproval(
  payload: QueryOrderApprovalRequestsArgs,
): Promise<OrderApprovalRequestConnection> {
  const { data } = await graphqlClient.query<
    Required<Pick<Query, "organizationOrderApprovalRequests">>,
    QueryOrganizationOrderApprovalRequestsArgs
  >({
    query: getOrganizationOrdersQueryDocument,
    variables: {
      ...payload,
    },
  });

  return data.organizationOrderApprovalRequests;
}
