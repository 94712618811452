<template>
  <VcModal
    :title="$t('shared.opus.contact_us_modal.title')"
    max-width="674px"
    is-mobile-fullscreen
    is-scrollable-on-desktop
  >
    <form @submit="onSubmit">
      <div class="flex flex-wrap justify-between">
        <VcInput
          v-model="firstName"
          required
          class="mb-6 w-full md:max-w-[300px]"
          :label="$t('shared.catalog.supplier_request_modal.first_name')"
          :placeholder="$t('pages.sign_up.first_name_placeholder')"
          :message="errors.firstName"
          :error="!!errors.firstName"
          :maxlength="64"
          name="firstName"
          autocomplete="given-name"
          disabled
        />

        <VcInput
          v-model="lastName"
          required
          class="mb-6 w-full md:max-w-[300px]"
          :label="$t('shared.catalog.supplier_request_modal.last_name')"
          :placeholder="$t('pages.sign_up.last_name_placeholder')"
          :message="errors.lastName ?? ''"
          :error="!!errors.lastName"
          :maxlength="64"
          name="lastName"
          autocomplete="family-name"
          disabled
        />

        <VcInput
          v-model="email"
          required
          class="mb-6 w-full md:max-w-[300px]"
          :label="$t('shared.catalog.supplier_request_modal.email')"
          :placeholder="$t('pages.sign_up.email_placeholder')"
          :message="errors.email ?? ''"
          :error="!!errors.email"
          :maxlength="64"
          name="email"
          autocomplete="email"
          disabled
        />

        <VcInput
          v-model="phone"
          class="mb-6 w-full md:max-w-[300px]"
          :label="$t('shared.catalog.supplier_request_modal.phone')"
          :placeholder="$t('shared.catalog.supplier_request_modal.phone_placeholder')"
          :message="errors.phone"
          :error="!!errors.phone"
          :maxlength="12"
          name="phone"
          autocomplete="phone"
          @input="handlePhone($event)"
        />
      </div>

      <VcLabel v-if="!!errors.question" required for="reasons" :error="!!errors.question">
        {{ $t("shared.opus.contact_us_modal.no_reason_selected_error") }}
      </VcLabel>
      <VcLabel required> {{ $t("shared.opus.contact_us_modal.questions_block_title") }} </VcLabel>
      <div id="reasons" class="mb-6 mt-3">
        <div class="flex flex-wrap gap-y-3 space-y-2 md:space-y-0">
          <div
            v-for="(reason, index) in contactReasons"
            :id="`${index}`"
            :key="index"
            class="flex w-full flex-col items-start space-y-2 md:w-1/2"
          >
            <VcRadioButton v-model="question" :value="reason.text" :label="reason.text" class="w-full">
              <span>{{ reason.text }}</span>
            </VcRadioButton>
          </div>
        </div>
      </div>

      <VcTextarea
        v-model="notes"
        :placeholder="$t('shared.opus.contact_us_modal.comment_label')"
        :label="$t('shared.catalog.supplier_request_modal.notes')"
        :disabled="loading"
        :error="!!errors.notes"
        :message="errors.notes"
        class="block w-full max-w-[625px]"
        :max-length="1000"
        rows="4"
      />
    </form>
    <template #actions="{ close }">
      <div class="flex space-x-3" :class="[isMobile ? 'w-1/2 grow' : 'w-full justify-between']">
        <VcButton color="secondary" class="w-1/2 grow md:w-auto md:grow-0 md:px-5" variant="outline" @click="close">
          {{ $t("common.buttons.cancel") }}
        </VcButton>

        <VcButton
          class="min-w-[145px] px-2 lg:mr-auto"
          :class="[isMobile && 'w-1/2 grow']"
          :disabled="loading || !meta.valid"
          @click="onSubmit()"
        >
          {{ $t("common.buttons.submit") }}
        </VcButton>
      </div>
    </template>
  </VcModal>
</template>

<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/yup";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { useField, useForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { object, string } from "yup";
import { OPUS_PHONE_PATTERN } from "@/core/constants";
import { globals } from "@/core/globals";
import { formatPhoneNumber } from "@/core/utilities";
import { useUser } from "@/shared/account";

interface IEmits {
  (event: "result"): void;
}

const emit = defineEmits<IEmits>();

const props = defineProps<IProps>();
const { t } = useI18n();

interface IProps {
  orderId?: string;
  orderNumber?: string;
}

const { userId } = globals;

const { loading, user, sendContactUsForm } = useUser();
const breakpoints = useBreakpoints(breakpointsTailwind);

const isMobile = breakpoints.smaller("md");

const schema = toTypedSchema(
  object({
    email: string().label("Email").required().email().max(64),
    firstName: string().label("First Name").required().max(64),
    lastName: string().label("Last Name").required().max(64),
    phone: string()
      .label("Phone")
      .nullable()
      .test(
        "length-12-or-empty",
        t("shared.opus.quick_connect.contact_section.phone_error"),
        (value) => !value || OPUS_PHONE_PATTERN.test(value),
      ),
    question: string().label("Question").required(),
    notes: string().label("Notes").max(1000),
  }),
);

const contactReasons = [
  { text: t("shared.opus.contact_us_modal.question_about_order") },
  { text: t("shared.opus.contact_us_modal.request_admin_permissions") },
  { text: t("shared.opus.contact_us_modal.question_about_account") },
  { text: t("shared.opus.contact_us_modal.other") },
];

const { errors, handleSubmit, meta } = useForm({
  validationSchema: schema,
  initialValues: {
    email: user.value.email,
    firstName: user.value.contact?.firstName,
    lastName: user.value.contact?.lastName,
    phone: formatPhoneNumber(user.value.contact?.phones?.[0] ?? ""),
    question: contactReasons[0].text,
    notes: "",
  },
  validateOnMount: false,
});

const { value: firstName } = useField<string>("firstName");
const { value: lastName } = useField<string>("lastName");
const { value: email } = useField<string>("email");
const { value: phone } = useField<string>("phone");
const { value: question } = useField<string>("question");
const { value: notes } = useField<string>("notes");

function handlePhone(event: Event) {
  const target = event.target as HTMLInputElement;
  const formattedValue = formatPhoneNumber(target.value);
  phone.value = formattedValue;
}

const onSubmit = handleSubmit(() => {
  const contactUsRequest = {
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    phone: phone?.value,
    question: question.value,
    note: notes?.value,
    orderId: props.orderId,
    orderNumber: props.orderNumber,
    userId,
  };

  void sendContactUsForm(contactUsRequest);
  emit("result");
});
</script>
